.arrangement-connect-groups {
  max-inline-size: 40rem;
  .notification {
    margin-bottom: 1rem;
  }
}

.arrangement-connect-groups__pills-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
  &:empty {
    padding: 0 !important;
  }
}
