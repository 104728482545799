.new-arrangement-header {
  display: flex;
}

.new-arrangement-header__icon {
  height: 2rem;
  margin-right: .75rem;
  width: 1.8125rem;

  svg, path {
    fill: $dark-grey;
  }
}

.new-arrangement-header__label {
  align-items: center;
  color: $anthracite;
  display: inline-flex;
  font-size: 1.25rem;
  font-weight: bold;
}

.new-arrangement-form-container {
  display: flex;
  flex-direction: column;

  .input,
  .textarea {
    -moz-appearance: none;
    border: 1px solid $input-border-color;
    color: $heading-light-color;
    flex: 1 0 auto;
    font-size: 1rem;
    font-weight: bold;
    padding: .5rem;
    width: 100%;

    &::placeholder {
      color: $muted-text-color;
    }

    &--invalid {
      border-color: $red;
    }
  }

  .input {
    height: 2.5rem;
    margin-bottom: .625rem;
  }
}
