.v2-modal-backdrop {
  align-items: center;
  background: rgba(0, 0, 0, 0.65);
  left: 0;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: center;
  position: fixed;
  top: 0;
  width: 100%;
}

.v2-modal-dialog {
  background: rgb(255, 255, 255);
  flex: 0 1 auto;
  margin-bottom: auto;
  margin-top: 25vh;
  max-width: 40rem;
  min-width: 31.25rem;
  position: relative;

  &--loading {
    @include loading-bar($height: 5px, $color: $primary);
  }

  &--large {
    margin-top: 10vh;
    margin-bottom: 10vh;
    height: 80vh;
    min-width: 40rem;
    display: flex;
    flex-direction: column;

    .v2-modal-body {
      flex: 1;
      height: 0;
    }
  }

  &--wide {
    min-width: 50rem;
    display: flex;
    flex-direction: column;
    margin-bottom: auto;
    margin-top: auto;

    .v2-modal-body {
      flex: 1;
      height: 0;
    }
  }

  &.v2-model-prompt {
    max-width: 25rem;

    &,
    .v2-modal-body {
      min-height: auto !important;
    }
  }

  .feedback {
    font-size: 1rem;
    font-weight: bold;

    &--regular {
      color: $text-color;
    }

    &--warning {
      color: $warning-color;
    }
  }
}

.v2-modal-close {
  position: absolute;
  right: 1.25rem;
  top: 1rem;

  &:hover,
  &:focus,
  &:active {
    background-color: $tm-neutral-pale;
    cursor: pointer;
  }

  &, &::before, &::after  {
    transition: all .3s ease-in-out;
  }
}

.v2-modal-header {
  align-items: center;
  border-bottom: 1px solid $blue-light-pale;
  display: flex;
  padding: .5rem 3.5rem .5rem .25rem;
}

.v2-modal-header__back-link {
  align-items: center;
  display: flex;
  height: 1.625rem;
  justify-content: center;
  width: 2rem;
}

.v2-modal-header__icon {
  fill: $body-color;
  flex-shrink: 0;
}

.v2-modal-header > .v2-modal-header__icon {
  margin-left: 1rem;
  margin-right: 1rem;
}

.v2-modal-title {
  color: $anthracite;
  font-size: 1.625rem;

  &:first-child {
    margin-left: 1.25rem;
  }
}

.v2-modal-body {
  color: rgb(180, 180, 180);
  display: flex;

  &.transitioning {
    overflow: hidden;
  }

  > * {
    flex: 1 0 100%;
  }
}

.v2-modal-footer {
  border-top: 1px solid $blue-light-pale;
}

.v2-modal-footer__button-container {
  display: flex;
  justify-content: flex-start;
  padding: .625rem 1.25rem;

  .button {
    margin-right: .625rem;
  }
  a-button {
    margin-right: .625rem;
  }
}

.v3-modal-footer {
  margin: 0.5rem 0;
}

.v3-modal-footer__button-container {
  display: flex;
  justify-content: flex-start;
  gap: 0.5rem;
}
