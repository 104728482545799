$navbar-breakpoint: $tablet;
$navbar-dropdown-radius: 0;
$navbar-dropdown-border-top: 0;
$navbar-bottom-box-shadow-size: none;
$navbar-z: 10001;
$navbar-fixed-z: 10001;

$navbar-method-width: 135px;

@import "~bulma/sass/components/navbar.sass";

.navbar-menu {
  padding: 0;
  box-shadow: none;
}

.navbar-burger {
  min-width: 3.125rem;
  span {
    height: 2px;
    transition: none; // cancel animation
  }
}

.navbar-item--stream.has-dropdown {
  display: block !important;
}

.navbar-item--stream,
.navbar-account-menu {
  .navbar-dropdown {
    border-left: 1px solid $tm-neutral-light;
    border-right: 1px solid $tm-neutral-light;

    .navbar-dropdown {
      border-top: 1px solid $tm-neutral-light;
    }
  }
}

.navbar-dropdown:not(.is-sub-dropdown) {
  display: none;
  padding-top: 0;
  padding-bottom: 0;
  box-shadow: none;

  &:after {
    background: url(../images/logo-colour-bar.png) no-repeat center bottom;
    background-size: cover;
    content: "";
    display: block;
    height: 3px;
  }

  .is-sub-dropdown & {
    margin-left: 100%;
    top: 0;

    @include mobile() {
      position: relative;
      margin-left: 0;
    }
  }
}

@include mobile() {
  .navbar-item.has-dropdown {
    &:not(.is-hoverable) {
      .navbar-dropdown {
        display: none;
      }
    }
    &.is-active {
      .navbar-dropdown {
        display: block;
      }
      &:after {
        display: none;
        height: 0;
      }
    }
  }

  .navbar-item--stream {
    width: calc(100% - #{$navbar-method-width+50});
    position: static;

    .navbar-dropdown {
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
    }
  }
}

.navbar {
  transition: transform .2s;
  .navbar-is-hidden & {
    transform: translateY(-100%);
  }

  &--clean {
    background: $tm-neutral-light;
  }

  &--assignment {
    padding-right: 3rem;
  }

  &--sticky {
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
  }

  &--redesign-transition {
    /* border-b border-gray-500 bg-white flex justify-between items-center h-20 lg:h-24 js-navigation-app */
    display: flex;
    justify-content: space-between;
    background: $white !important;
    border-bottom: 1px solid #C9C5BC;
    height: 5rem;

    .navbar-end {
      display: flex;
    }

    @media screen and (min-width: 1440px) {
      height: 6.75rem;
    }

    a.redesign-logo {
      display: flex;
    }

    img.redesign-logo {
      max-height: 100%;
      max-width: 100%;
      height: auto;
      vertical-align: middle;
    }

    .redesign-nav-item {
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      height: 100%;
      color: $tm-neutral-black;
      &:hover {
        color: $tm-neutral-extraheavy;
      }
      i {
        font-size: 2rem;
      }
      position: relative;
      width: 4rem;
      &__label {
        display: none;
      }
      @media screen and (min-width: 1440px) {
        justify-content: flex-end;
        width: 6rem;
        &__label {
          display: block;
          font-size: 16px;
          line-height: 20px;
          overflow: hidden;
          height: 40%;
        }
      }
    }

    //bg-white border rounded-b-lg border-gray-500 overflow-hidden shadow-xl
    .redesign-nav-dropdown {
      display: none;
      position: absolute;
      right: 0;
      top: 100%;
      background-color: $white;
      border: 1px solid #C9C5BC;
      border-radius: 0 0 9px 9px;
      width: 100%;
      font-size: 18px;
      overflow: hidden;
      min-width: fit-content;
      //block py-2 px-4 bg-white hover:bg-gray-400 focus:bg-gray-400 active:bg-gray-400 whitespace-nowrap
      a {
        color: $tm-neutral-black;
        display: block;
        padding: 9px 18px;
        white-space: nowrap;
        text-align: left;

        &:hover, &:focus {
          background-color: #D7D7D7;
        }
      }
    }
    .redesign-nav-item.is-active {
      .redesign-nav-dropdown {
        display: block;
      }
    }
  }
}

.is-sub-dropdown,
.navbar-item--stream {
  .navbar-item,
  .navbar-link {
    display: block !important;
  }
}

@include tablet() {
  .is-sub-dropdown {
    .navbar-link {
      &:after {
        transform: rotate(225deg);
      }
    }
  }
}

.navbar-item {
  color: $body-color;
  padding: 0.5rem 0.75rem;
  .navbar-dropdown & {
    padding: 0.5rem 0.75rem;
  }

  &.is-sub-dropdown {
    padding: 0 !important;
  }

  .navbar-link:after {
    margin-top: -0.45em;
    border-color: currentColor;
    border-radius: 0;
    border-width: 2px;
  }

  border-color: $body-color;
  font-family: $family-sans-serif;
  font-size: 0.8125rem;
  font-weight: $weight-semibold;

  [class^="icon-"],
  [class*=" icon-"] {
    margin-right: 0.25rem;
    margin-left: 0.25rem;
    font-size: 0.9rem;
    .navbar-item-hide-all & {
      display: flex;
      margin-right: 0;
    }

    @include tablet-only() {
      margin-right: 0;
    }
  }
  .button {
    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0;
    }
  }

  &.navbar-item-hide-none {
    [class^="icon-"],
    [class*=" icon-"] {
      margin-right: 0.5rem;
    }

    .button {
      [class^="icon-"],
      [class*=" icon-"] {
        margin-right: 0;
      }
    }
  }

  @include tablet() {
    &.is-condensed {
      padding-left: 0.5em;
      padding-right: 0.5em;
    }
  }

  .navbar--clean & {
    color: $tm-neutral-extrablack;
    font-family: $family-sans-serif;
    font-weight: $weight-bold;

    font-size: 1.1rem;
    @include desktop() {
      font-size: 1.3rem;
    }
  }

  &.is-hidden {
    display: none;
  }

  &.is-current {
    span.navbar-item__text {
      display: inline-block !important;
    }
  }

  &--loading {
    @include loading-bar(2px, $tm-red);
  }
}

.navbar-item__meta {
  .navbar--clean & {
    font-weight: $weight-normal;
  }
}

.navbar-item-container {
  margin: 0;
  padding: 0;
  display: flex;
  position: relative;
}

.navbar-start {
  .navbar--assignment & {
    flex: 0 0 45%;
    margin-right: 0;
    @include truncate-text();
  }
}

.navbar-end {
  .navbar-item-container {
    .navbar-item {
      width: 100%;
      &.home-icon .icon-home,
      &.search-icon .icon-search {
        font-size: 1rem;
      }
      .navbar-item__text {
        display: none;
      }
      @include until($tablet) {
        &.home-icon .icon-home {
          font-size: 0.9rem;
        }
        .navbar-item__text {
          display: inline-block;
        }
      }
    }
  }
}
a.navbar-item:hover,
a.navbar-link:hover {
  color: $body-color;
  background-color: $tm-neutral-light;
}

@media (hover: hover) {
  .navbar-item.has-dropdown:not(.is-sub-dropdown):hover {
    &:after {
      position: absolute;
      bottom: 0;
      left: 0;
      background: url('../images/logo-colour-bar.png') no-repeat center bottom;
      background-size: cover;
      content: "";
      display: block;
      height: 3px;
      width: 100%;
    }
  }
}

.navbar-item:active,
.navbar-item.is-active {
  > .navbar-dropdown {
    display: block;

    .navbar-dropdown {
      display: none;
    }

    .navbar-item:active,
    .navbar-item.is-active {
      > .navbar-dropdown {
        display: block;
      }
    }
  }

  &:after {
    .navbar--clean & {
      display: none;
    }
    position: absolute;
    background-image: none !important;
    bottom: 0;
    left: 0;
    content: "";
    background-color: $tm-neutral-dark;
    height: 3px;
    width: 100%;
  }

  // Do not show active state on menu item that has a dropdown:
  &.has-dropdown:after { display: none; }
}

@include tablet() {
  .navbar-menu .navbar-start > .navbar-item {
    border-right: 1px solid $tm-neutral;
  }
}

.navbar-account-menu {
  background-color: $white !important;
  color: $tm-neutral-extraheavy !important;

  .navbar-dropdown {
    a.navbar-item {
      background-color: $white !important;
      color: $tm-text-gray !important;
      display: block;
      height: $navbar-item-height;
      line-height: 2.8;
      padding: 0.5em 20px;
      @include tablet() {
        text-align: right;
      }
      white-space: nowrap;
      &:hover {
        color: $tm-text-red !important;
      }
      &:active {
        background-color: $tm-neutral-light !important;
        color: $tm-text-red !important;
      }
    }
  }

  // Hier moeten initialen van
  .navbar-link {
    align-items: center;
    display: flex;
    background-color: inherit !important;
    color: inherit !important;
    @include tablet() {
      padding-right: 0.75rem;
    }

    &:before {
      content: "";
      background: url('../images/icon-user-sprite.png') no-repeat;
      background-position: top left;
      background-size: 300%;
      display: inline-block;
      position: relative;
      width: 28px;
      height: 28px;
      margin-right: 0.4rem;

      @include tablet-only() {
        margin-right: 0;
      }
    }

    &:after {
      @include tablet() {
        display: none;
      }
    }
  }

  &--teacher {
    .navbar-link:before {
      background: url('../images/icon-teacher-sprite.png') no-repeat;
      background-position: top left;
      background-size: 300%;
    }
  }

  .navbar-account-menu-username {
    display: none;
    @include tablet-only() {
      & {
        display: block;
      }
    }
  }

  &:hover,
  &:focus {
    background-color: $white !important;
    color: $tm-text-red !important;
    outline: 0;
    .navbar-link {
      background-color: inherit !important;
      color: inherit !important;
      &:before {
        background-position: center;
      }
    }
  }
}

.navbar-item.has-dropdown {
  &:hover,
  &.is-active {
    .navbar-link {
      background-color: transparent;
    }
  }

  &:hover,
  &.is-active {
    > .navbar-link {
      background-color: $navbar-item-hover-background-color;
    }
  }
}

.navbar-brand {
  height: 100%;
}

.navbar-method {
  background: no-repeat center;
  background-size: 100%;
  border-right: 1px solid #000;
  text-indent: -9999em;
  display: inline-block;
  width: $navbar-method-width;
  height: 100%;
  min-height: 3.125rem;
  min-width: $navbar-method-width;
}

.navbar-stream-name {
  font-family: $family-sans-serif;
  font-weight: $weight-bold;
  max-width: 250px;
  @include truncate-text;
  display: block;
  height: 50px;
  line-height: 2.5;

  span.stream-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.icon-star--for-streamname {
  margin: 0;
  padding: 0;
  width: 1rem;
  margin-left: 0.1rem!important;
  margin-right: 0.1rem !important;
  &:first-child {
    margin-left: 0.4rem !important;
  }
  &:only-child {
    margin-left: 0.4rem !important;
    margin-right: 0.4rem !important;
  }
}

// Default navbar colors for my-edition
$navbar-logo: url('../images/logo-thiememeulenhoff.svg');
$navbar-brand-divider-color: #6994ba;
$navbar-primary-color: #fff;
$navbar-primary-background-color: #6994ba;
$navbar-primary-background-hover-color: lighten($navbar-primary-background-color, 5%) !default;
$navbar-secondary-color:  #fff;
$navbar-secondary-background-color: #6994ba;
$navbar-item-color: $navbar-secondary-color;
$navbar-item-icon-color: $navbar-secondary-color;
$navbar-item-hover-color: darken($navbar-item-color, 5%);
$navbar-item-active-color: darken($navbar-item-color, 5%);
$navbar-item-active-text-color: $navbar-secondary-color;
$navbar-item-icon-hover-color: lighten($navbar-item-icon-color, 5%);
$navbar-item-background-hover-color: lighten($navbar-secondary-background-color, 5%);
$navbar-secondary-background-is-current-color: lighten($navbar-secondary-background-color, 10%);
$navbar-item-divider-color: rgba($navbar-item-color, .3);

@include navbar-theme(
    $navbar-logo,
    80%,
    $navbar-brand-divider-color,
    $navbar-primary-color,
    $navbar-primary-background-color,
    $navbar-primary-background-hover-color,
    $navbar-secondary-color,
    $navbar-secondary-background-color,
    $navbar-secondary-background-is-current-color,
    $navbar-item-color,
    $navbar-item-hover-color,
    $navbar-item-active-color,
    $navbar-item-active-text-color,
    $navbar-item-icon-color,
    $navbar-item-icon-hover-color,
    $navbar-item-background-hover-color,
    $navbar-item-divider-color
)
