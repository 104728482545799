.section-header__button-container {
  display: flex;
  margin-right: .5rem;
  .button:not(:last-child) {
    margin-right: .75rem;
  }

  a-button:not(:last-child) {
    margin-right: .75rem;
  }
}

a-button.back-to-my-arrangements-button {
  margin-right: .25rem;
}
