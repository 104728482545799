$dropdown-content-offset: 0;
$dropdown-content-radius: 0;
$dropdown-content-shadow: none;
$dropdown-content-z: 20;
$dropdown-min-width: 16rem;

$dropdown-content-background-color: $tm-neutral-pale;
$dropdown-border-color: $tm-neutral-black;
$dropdown-item-color: $tm-neutral-black;
$dropdown-item-color-search-term: $tm-red;
$dropdown-item-hover-color: $tm-neutral-extrablack;
$dropdown-item-hover-background-color: $tm-neutral-dark;
$dropdown-item-active-color: $tm-neutral-black;
$dropdown-item-active-background-color: $tm-neutral;
$dropdown-divider-color: $tm-neutral-black;

.dropdown {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-flex;
  position: relative;
  vertical-align: top;
  text-align: left;
  max-width: 100%;
}

.dropdown__menu {
  padding-top: $dropdown-content-offset;
  z-index: $dropdown-content-z;
  max-height: 400px;
  position: absolute;
}

.dropdown__content {
  background-color: $dropdown-content-background-color;
  border-radius: $dropdown-content-radius;
  box-shadow: $dropdown-content-shadow;
  max-width: calc(100vw - 3rem);
  width: max-content;
  overflow-y: auto;
  overflow-x: hidden;
}

.dropdown__trigger {
  align-items: center;
  background: $dropdown-content-background-color;
  cursor: pointer;
  display: flex;
  line-height: 1.5;
  outline: 0;
  padding: 0.375rem 1rem;
  text-align: left;

  .dropdown--full-width & {
    justify-content: space-between;
  }

  .dropdown--open & {
    cursor: default;
  }
}

.dropdown__trigger-label {
  font-weight: $weight-bold;

  .dropdown--full-width & {
    display: flex;
  }

  .dropdown--primary & {
    font-size: 1.5rem;
  }

  .dropdown--secondary & {
    font-weight: normal;
    font-size: 1rem;
  }
}

.dropdown__trigger-arrow {
  line-height: 1;
  font-size: 1.3rem;
  height: 1rem;
  margin-left: auto;
  transform-origin: center 60%;

  .dropdown--open & {
    transform: rotate(180deg);
    transform-origin: center;
  }
}

.dropdown__search-input {
  color: $dropdown-item-color;
  font-size: 1rem;
  padding: 0.2rem 0.5rem;
  width: 100%;
  border-radius: 0;
  border: 1px solid $dropdown-item-color;
  outline: none;
  &:focus {
    outline: none;
  }
}

.dropdown__item .search-term {
  color: $dropdown-item-color-search-term;
}

.dropdown__item,
.dropdown__setting,
.dropdown__trigger,
.dropdown__filter {
  color: $dropdown-item-color;
  position: relative;
  font-size: inherit;
}

.dropdown__item,
.dropdown__setting,
.dropdown__filter {
  display: block;
  font-weight: 500;
  padding: 0.625rem 2.85rem 0.625rem 1rem;
}

.dropdown__filter {
  padding: 0.625rem;
}

.dropdown__item:hover {
  background-color: $dropdown-item-hover-background-color;
  color: $dropdown-item-hover-color;
}

.dropdown__item--selected {
  background-color: $dropdown-item-active-background-color;
  color: $dropdown-item-active-color;
  cursor: default;
  font-weight: bold;
}
.dropdown__item--unselectable:hover {
  background-color: inherit;
}

.dropdown__checkbox {
  margin-right: 0.5rem;
}

.dropdown__divider {
  padding: 1rem 1rem 0.5rem;
  position: relative;
  display: flex;
  align-items: center;

  .dropdown__divider--label {
    color: $dropdown-divider-color;
    text-transform: uppercase;
    font-size: 12px;
    font-weight: $weight-bold;
    position: relative;
    padding-right: $spacer;
  }

  &:after {
    z-index: 0;
    content: '';
    height: 1px;
    background-color: $dropdown-divider-color;
    flex: 1;
  }
}

.dropdown--full-width {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

$dropdown-dark-content-background-color: $dark-grey;
$dropdown-dark-border-color: #5f6264;
$dropdown-dark-item-color: $white;
$dropdown-dark-item-hover-color: $yellow-link-active;
$dropdown-dark-item-hover-background-color: $black;
$dropdown-dark-item-active-color: $yellow-link-active;
$dropdown-dark-item-active-background-color: $black;
$dropdown-dark-divider-color: #757575;

$dropdown-blue-content-background-color: $cockpit-blue;
$dropdown-blue-border-color: #5f6264;
$dropdown-blue-item-color: $white;
$dropdown-blue-item-hover-color: $white;
$dropdown-blue-item-hover-background-color: $cockpit-blue;
$dropdown-blue-item-active-color:$white;
$dropdown-blue-item-active-background-color: $cockpit-blue;
$dropdown-blue-divider-color: #757575;

.dropdown--dark {
  .dropdown__content {
    background-color: $dropdown-dark-content-background-color;
  }

  .dropdown__trigger {
    background: $dropdown-dark-content-background-color;
  }

  .dropdown__item,
  .dropdown__setting,
  .dropdown__trigger {
    color: $dropdown-dark-item-color;
  }

  .dropdown__item:hover {
    background-color: $dropdown-dark-item-hover-background-color;
    color: $dropdown-dark-item-hover-color;
  }

  .dropdown__item--selected {
    background-color: $dropdown-dark-item-active-background-color;
    color: $dropdown-dark-item-active-color;
  }

  .dropdown__divider {
    .dropdown__divider--label {
      color: $dropdown-dark-divider-color;
    }

    &:after {
      background-color: $dropdown-dark-divider-color;
    }
  }
}

.dropdown--minimalistic {
  .dropdown__trigger {
    background-color: transparent;
    padding: 0.375rem 0;
  }

  .dropdown__menu {
    margin-left: -1rem;
    margin-right: -1rem;
  }

  .text-light & .dropdown__trigger-label {
    color: $heading-light-color;
    font-size: 1rem;
    font-weight: normal;
  }
}

.dropdown--minimalistic.dropdown--open {
  .dropdown__trigger {
    margin-left: -1rem;
    margin-right: -1rem;
    padding-left: 1rem;
    padding-right: 1rem;

    background-color: $dropdown-content-background-color;
  }
}

.dropdown--highlighted {
  .dropdown__trigger-label {
    color: $tm-red;
  }
  &.dropdown--open .dropdown__trigger {
    margin-left: 0;
    margin-right: 0;
  }
}

.dropdown--loading:after {
  display: block;
  position: absolute;
  right: -36px;
  content: "";
  width: 16px;
  height: 100%;
  background: url('../images/loader.gif') no-repeat center center;
}

.dropdown--inline {
  display: inline-block;
  vertical-align: middle;

  .dropdown__trigger {
    min-width: 8.75rem;
    padding: 0.125rem 0.5rem;
  }

  .dropdown__trigger-label {
    @include empty-placeholder();
  }

  .dropdown__item {
    padding: 0.325rem 2rem 0.325rem 0.5rem;
  }
}

.dropdown--inline-small {
  .dropdown__trigger {
    min-width: auto;
  }
}

.dropdown--light {
  .dropdown__trigger {
    background: $white;
    border: 1px solid $tm-neutral;
  }

  .dropdown__item:hover {
    background-color: $interaction-active-color;
    color: $white;
  }

  .dropdown__item--selected {
    background-color: $interaction-active-color;
    color: $white;
  }

  .dropdown__content {
    border: 1px solid $tm-neutral;
    background-color: $white;
  }
}

.dropdown--blue {
  .dropdown__content {
    background-color: $dropdown-blue-content-background-color;
  }

  .dropdown__trigger {
    background: $dropdown-blue-content-background-color;
  }

  .dropdown__item,
  .dropdown__setting,
  .dropdown__trigger {
    color: $dropdown-blue-item-color;
  }

  .dropdown__item:hover {
    background-color: $dropdown-blue-item-hover-background-color;
    color: $dropdown-blue-item-hover-color;
  }

  .dropdown__item--selected {
    background-color: $dropdown-blue-item-active-background-color;
    color: $dropdown-blue-item-active-color;
  }

  .dropdown__divider {
    .dropdown__divider--label {
      color: $dropdown-blue-divider-color;
    }

    &:after {
      background-color: $dropdown-blue-divider-color;
    }
  }
}
