$results-background-color: $tm-neutral-light;
$results-chapter-spacing: $spacer * 2;
$results-item-spacing: $spacer;
$results-left-width: 22.5rem;
$results-left-width-with-progress: 28rem;
$results-left-width-education-level: 35rem;
$results-left-width-education-level-with-progress: 40.5rem;
$results-column-width: 4.5rem;
$results-education-level-column-width: 11rem;
$results-header-height: 5rem;

.container-grid--results {
  margin-top: $results-header-height;
  height: calc(100vh - #{$navbar-height} - #{$results-header-height});
  body.with-redesign-transition-navbar & {
    height: calc(100vh - 5rem - #{$results-header-height});
    @media screen and (min-width: 1440px) {
      height: calc(100vh - 6.75rem - #{$results-header-height});
    }
  }
}
.container-grid--results-paragraph {
  height: calc(100vh - #{$navbar-height}*2 - #{$results-header-height});
  body.with-redesign-transition-navbar & {
    height: calc(100vh - 5rem - #{$navbar-height} - #{$results-header-height});
    @media screen and (min-width: 1440px) {
      height: calc(100vh - 6.75rem - #{$navbar-height} - #{$results-header-height});
    }
  }
}

.page-header--results {
  display: flex;
  flex-direction: row;
  justify-content: normal;
  padding: baseline(1) 0 0 0;
  z-index: 10000;
  flex-wrap: nowrap;
  width: 100vw;
  height: $results-header-height;
  margin-top: -$results-header-height;
  background-color: $white;
  position: fixed;

  .page-header__title {
    font-family: $family-serif;
    color: $heading-light-color;
    font-size: 1.5em;
    line-height: 1.25;
    margin: 0;
    padding: 0 2.25rem 1.125rem;

    position: relative;

    max-width: calc(100vw - #{$results-left-width});

    @include truncate-text();

    &--secondary {
      color: $tm-neutral-heavy;
      background: #fff;
      position: relative;
      width: $results-left-width;
      padding-right: 0;
      @include tablet() {
        flex-shrink: 0;
      }
    }

    &:not(.page-header__title--secondary) {
      @include mobile() {
        display: none;
      }
    }
  }

  .page-header__actions {
    flex-grow: 1;
    padding-right: $spacer * 4;
    text-align: right;
  }

  .page-header__back {
    color: #333;
    vertical-align: baseline;
    font-size: 0.9em;

    @include truncate-text();

    &:before {
      content: "\e608";
      font-family: TMPika;
      color: #FC4A00;
      font-size: 1.2em;
      line-height: 1;
      vertical-align: text-bottom;
    }

    &:hover:before {
      margin-left: -3px;
      margin-right: 3px;
    }
  }
}

.results-side-container-paragraph {
  width: 100vw;
  position: sticky;
  left:0;
  z-index:9999;
}

.results-side {
  $results-side: &;

  background: #ffffff;
  width: $results-left-width;
  &--with-progress {
    width: $results-left-width-with-progress;
  }

  flex-shrink: 0;
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 99;

  &--with-education-levels {
    width: $results-left-width-education-level;
    &.results-side--with-progress {
      width: $results-left-width-education-level-with-progress;
    }
  }

  &--with-group-dropdown {
    z-index: 100;
  }

  &__sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 9999;
  }
}

.results-no-group {
  display: flex;
  flex-wrap: nowrap;

  &__inner {
    position: -webkit-sticky;
    position: sticky;
    left: $results-left-width;
    padding: baseline(1.5);
    min-width: baseline(16.5);
    max-width: calc(100vw - #{$results-left-width});
  }
}

.result-side__headers {
  padding: 0 2.25rem baseline(1);
  width: 100%;
  z-index: 2;
  background: #fff;
  position: relative;
  line-height: 2;
  display: inline-flex;
  justify-content: flex-end;
}

.result-side__header-education-levels {
  width: $results-education-level-column-width;
}

.results-group {
  height: 10.25rem;
  padding: baseline(1.5) baseline(1.5) baseline(0.75) baseline(1.5);
  z-index: 3;
  background: #fff;
  position: relative;
}
.results-group--paragraph {
  height: 6.45rem;
}

.results-students {
  z-index: 2;
  background: #fff;
  position: relative;

  &__student,
  &__teacher {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0.25rem baseline(1.5);

    scroll-margin-top: 13.5rem;
    scroll-snap-margin-top: 13.5rem;
    .container-grid--results-paragraph & {
      scroll-margin-top: 10rem;
      scroll-snap-margin-top: 10rem;
    }
  }

  &__teacher .results-students__name {
    font-weight: bold;
  }

  &__name {
    flex: 1;
    padding-right: 0.25rem;

    @include truncate-text();
  }

  &__education-level {
    width: $results-education-level-column-width;
    text-align: left;
    margin-right: 2rem;
    flex: 0 0 auto;
  }
}

.results-body {
  $results-body: &;

  display: flex;
  flex-wrap: nowrap;
  background-color: $results-background-color;

  padding-right: $results-chapter-spacing;
  margin-right: $results-chapter-spacing;

  &--with-rotated-title {
    padding-right: baseline(5);
  }

  &__sticky {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    display: block;
    background-color: $results-background-color;
    z-index: 3;

    &--item {
      top: 0;
      text-align: center;
      padding-left: $results-chapter-spacing;
    }
  }

  &__item {
    display: flex;
  }

  &__student-assignment,
  &__student-item-average
  {
    position: relative;
    padding: 0.25rem 0;
  }

  &__student-assignment {
    cursor: pointer;
  }

  &__sub-columns {
    display: flex;
    flex-direction: row;
    margin-left: $results-chapter-spacing;
  }

  &__sub-column {
    min-width: $results-column-width;
    margin: 0 0 $spacer;
    background-color: $results-background-color;

    &:first-child {
      #{$results-body}__student-assignment,
      #{$results-body}__student-item-average {
        margin-left: -#{$results-chapter-spacing};
        padding-left: $results-chapter-spacing;
      }
    }
    &:last-child {
      #{$results-body}__student-assignment,
      #{$results-body}__student-item-average {
        margin-right: -#{$results-chapter-spacing};
        padding-right: $results-chapter-spacing;
      }
    }
  }

  &__item-title {
    display: inline-grid;
    padding-top: baseline(0.5);
    height: baseline(2.25);
    z-index: 1;
  }

  &__item-title-content {
    @include truncate-text();
    line-height: 1.5;
    &--for-test {
      @include icon-before($icon: $pika-flag, $margin-right: 0.5em);
    }
  }

  &__number-header,
  &__item-average-header {
    text-align: center;
    display: flex;
    flex-direction: column;
  }

  &__item-average-header {
    min-width: $results-column-width;
  }

  &__number {
    position: relative;
    white-space: nowrap;
    font-size: 1rem;
    overflow: hidden;
    margin: 0 0.1rem $spacer;
    min-height: 2.625rem;
  }

  &__number-fill {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    background-color: rgba(255,255,255,0.3);
  }

  &__column {
    cursor: default;
    color: $body-color;
    background-color: $results-background-color;
    &--paragraph {
      border-right: $results-chapter-spacing solid $white;
      padding-right: $results-chapter-spacing;
      &:last-child {
        border-right: 0 none;
      }
    }

    &--hoverable {
      &:hover {
        #{$results-body}__title:before, #{$results-body}__student, #{$results-body}__average {
          background-color: $table-highlight-color;
        }
      }
    }
  }

  &__column-link {
    color: inherit;
  }

  &__cell--hover {
    background-color: $table-highlight-color;
  }

  &__title {
    height: 10.25rem;
    width: $results-column-width;
    position: relative;
    box-sizing: content-box;

    &:before {

      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      transform-origin: left bottom;
      transform: skew(-40deg);
      height: 10.25rem;
      width: $results-column-width;
    }
  }

  &__content-title {
    transform: rotate(-50deg);
    transform-origin: bottom left;
    left: 100%;
    position: absolute;
    line-height: 3.6rem;
    width: 10rem;
    box-sizing: border-box;
    bottom: 0;
    margin: 0;

    @include truncate-text();
  }

  &__average {
    position: relative;
    width: $results-column-width;
  }
  &__average--item {
    width: auto;
    min-width: $results-column-width;
  }

  &__student {
    display: block;
    position: relative;
    width: $results-column-width;
    padding: 0.25rem 0;

    &:last-child {
      padding-bottom: 1.5rem !important;
    }
  }

  &__student-action {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($white, 0.5);
    transition: background-color 100ms;

    &--selected {
      background-color: rgba($white, 0);
    }
  }
  &__student-action--sequence {
    &:before {
      content: ' ';
      width: $results-column-width;
      height: 100%;
      position: absolute;
      left: -$results-column-width + 0.1rem;
    }
  }
  &__student-action--sequence &__student-action-checkbox {
    left: -$results-column-width + 0.1rem;
  }

  &__student-action-checkbox {
    left: 1px;
    pointer-events: none;
    position: absolute;
    top: 10px;
  }

  &__student-average {
    width: calc(#{$results-column-width} - 0.2rem);
  }
}

.results-body__student-average,
.results-body__result {
  display: block;
  position: relative;
  text-align: center;
  line-height: 2;
  padding: 0;
  margin: 0 0.1rem;

  &--disabled {
    background: transparent;
  }
}

.results-body__student-progress {
  width: 5rem;
  margin-right: 0;
  margin-left: 0.5rem;
}

.results-action-menu {}
.results-action-top {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background: $tm-neutral-dark;
  z-index: 10002;
  padding: $spacer*2;
  min-height: 9rem;
  i[class^='icon-'] {
    margin-right: 0.25rem;
  }
  small {
    display: block;
    padding-top: 0.5rem;
  }
}

.student-assignment-action {
  margin: 2rem auto;
  max-width: 600px;
}


// back- and foreground colors for scores
.exercise-attempts-nav {
  margin: 16px 0;
  user-select: none;
}

.attempt-nav-item {
  display: inline-block;
  font-size: 20px;
  line-height: 60px;
  white-space: nowrap;
}

.attempt-number {
  display: inline-block;
  font-size: .8em;
  vertical-align: middle;
}

.attempt-link {
  border-radius: 3px;
  display: inline-block;
  height: 40px;
  line-height: 40px;
  margin-right: .25em;
  padding: 0 8px;
  position: relative;
  vertical-align: middle;

  @include score-label();

  &.is-current:before {
    background-color: inherit;
    border-radius: 2px;
    content: '';
    height: 6px;
    position: absolute;
    right: 0;
    left: 0;
    bottom: -9px;
  }
}
.attempt {
  background-color: $tm-neutral-light;
  overflow: hidden;
  position: absolute;
  opacity: 0;
  pointer-events: none;
  &.is-showing {
    opacity: 1;
    pointer-events: all;
    background-color: transparent;
    position: static;
    transition: background-color 1s ease-in-out 0ms;
    overflow: visible;
  }
}
