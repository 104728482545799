$item-height: 2.75rem;

.dropdown--cockpit-methods {
  &:not(.dropdown--open) {
    .dropdown__trigger,
    .dropdown__content {
      background: $white;
    }
  }
  .dropdown__trigger,
  .dropdown__content {
    padding: 0 0 0 0;
  }
  .dropdown__trigger {
    padding-right: 1rem;
    .cockpit-header__logo {
      margin: 0.5rem 1rem;
    }
  }
}

.dropdown--arrangements,
.dropdown--groups {
  min-width: 10rem;

  &.dropdown--disabled {
    cursor: default;
    opacity: .5;
    pointer-events: none;
    user-select: none;
  }

  .dropdown__trigger {
    background: $white;
    border: 1px solid $form-element-border-color;
    color: $form-element-text-color;
    display: flex;
    font-family: $form-element-font-family;
    font-size: $form-element-font-size;
    height: 2.5rem;
    justify-content: space-between;
    line-height: 0;
    outline: 1px solid transparent;
    padding: .1875rem .5rem 0 1rem;
    width: 100%;

    .icon {
      font-size: .65rem;
      height: .65rem;
      width: .65rem;

      &::before {
        font-size: 1em;
      }
    }
  }

  &.dropdown--open {
    .dropdown__trigger {
      outline-color: $form-element-border-color;
    }
    .dropdown__menu {
      top: calc(100% + 1px);
    }
  }

  .dropdown__content {
    overflow: auto;

    .v2-modal-body & {
      max-height: calc(#{$item-height} * 4);
    }
  }
}

.dropdown--arrangements {
  .dropdown__content {
    .dropdown__item {
      height: $item-height;
    }
  }
}

