$icon-size: 1.25rem;

.input-wrapper {
  align-items: center;
  display: flex;
  position: relative;
}

.input--arrangements {
  -webkit-appearance: textfield;
  background: $white;
  border: 1px solid $form-element-border-color;
  color: inherit;
  display: flex;
  font-family: inherit;
  font-size: inherit;
  height: 2.5rem;
  outline: 1px solid transparent;
  padding: 0 2.5rem 0 .5625rem;
  width: 100%;

  &:focus {
    outline-color: $form-element-border-color;
  }

  &[disabled] {
    &,
    + .icon {
      opacity: .5;
    }
  }

  + .icon {
    font-size: $icon-size;
    height: $icon-size;
    position: absolute;
    right: .75rem;
    top: 50%;
    transform: translateY(-50%);
    width: $icon-size;

    &::before {
      font-size: 1em;
    }
  }
}
