%arrangement-item-body-styles {
  color: $heading-light-color;
  font-family: $family-sans-serif;
  font-weight: bold;
}

.arrangements-container {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin: 0 auto;
  max-width: $arrangement-max-container-width;
  position: relative;
  width: 100%;
}

.arrangements-list__header {
  display: flex;
  justify-content: space-between;
}

.arrangements-list__filter-container {
  display: flex;
}

.arrangements-list__filter-component {
  &:not(:last-child) {
    margin-right: 1.25rem;
  }
}

.arrangements-list__content {
  flex: 1;
}

.arrangements-list__content-inner {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-bottom: 4rem;
}

.arrangements-index--empty {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  justify-content: center;
  margin-top: 12%;

  .arrangement-index__icon {
    height: auto;
    opacity: .5;
    width: 5.5rem;

    * {
      fill: $primary-button-color;
    }
  }

  .arrangement-index__header {
    color: $dark-grey;
    font-size: 1.25rem;
    font-family: $font-family-header;
    margin: 1.25rem 0 .5rem;
  }

  .arrangement-index__subheader {
    color: $heading-light-color;
    font-family: $family-sans-serif;
    font-size: .875rem;
  }
}

/* Arrangement (list)item */
.arrangement-item {
  display: flex;
  filter: none;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  transition: all .3s ease;

  &.card--portrait {
    justify-content: flex-start;
    text-align: center;

    .arrangement-item__header {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-top: 1rem;
      .arrangement-item__title-label,
      .arrangement-item__title-wrapper {
        text-align: center;
        width: 100%;
      }
    }

    .arrangement-item__template-icon {
      margin-bottom: .75rem;
      opacity: .5;
      transition: opacity .3s ease-in-out;
      width: 3rem;
      color: var(--tm-color-blue-500);
    }
    &:where(:hover) {
      color: var(--tm-color-blue-600);
    }

    .arrangement-item__keywords-container {
      font-size: .75rem;
    }

    .arrangement-item__description {
      color: var(--tm-color-grey-500);
      font-size: .875rem;
      font-weight: normal;
      line-height: 1.2;
      margin: 0;
      white-space: pre-line;
    }
    &:where(:hover) {
      color: var(--tm-color-grey-600);
    }
  }

  &:hover {
    .arrangement-item__template-icon {
      opacity: 1;
    }
  }
}

.arrangement-item__options-modal-trigger {
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
  width: 3.125rem;
  height: 3.125rem;

  .arrangement-item__options-modal-trigger-icon circle {
    fill: $heading-light-color;
  }

  &:hover .arrangement-item__options-modal-trigger-icon circle {
    fill: $dark-grey;
  }

  &:focus,
  &:active {
    outline: none
  }
}

.arrangement-item__title-wrapper {
  align-items: center;
  display: flex;
  margin-bottom: .75rem;
  width: calc(100% - 3.125rem);
}

.arrangement-item__title-icon {
  fill: $body-color;
  flex-shrink: 0;
  margin-right: .7rem;
  width: 1.25rem;
}

.arrangement-item__title-label {
  color: $body-color;
  font-family: $family-serif;
  font-size: 1.25rem;
  line-height: 1.25;
  word-break: break-word;
}

/* Link to group button */
.arrangement-item__link-button {
  align-self: flex-start;
  margin-bottom: auto;
  margin-top: .5rem;
}

.arrangement-item__connected-groups {
  .connected-groups__label {
    font-weight: bold;
  }

  .connected-groups__groups {
    font-size: 1rem;
    margin-top: .375rem;
  }
}

/* Description */
.arrangement-item__description {
  @extend %arrangement-item-body-styles;
  font-size: .75rem;
  margin-top: 1rem;
  white-space: pre-line;
}

/* Footer */
.arrangement-item__footer {
  @extend %arrangement-item-body-styles;
  border-top: 1px solid $light-grey;
  font-size: .875rem;
  padding-top: .5rem;

  display: flex;
  justify-content: space-between;
}
