.template-item {
  border: 1px solid transparent;
  display: flex;
  filter: none;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  transition: all .3s ease;

  &:hover {
    border-color: $form-element-border-color;

    .template-item__icon {
      opacity: 1;
    }
  }
}

.template-item__inner {
  align-items: center;
  color: $base-text-color;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  text-align: center;
}

.template-item__icon {
  opacity: .5;
  transition: all .3s ease;
}
