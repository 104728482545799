.section-header {
  background: $white;
  border-bottom: $arrangement-section-header-border-bottom solid $form-element-border-color;

  &__inner {
    align-items: center;
    display: flex;
    height: $arrangement-section-header-height;
    justify-content: space-between;
    margin: 0 auto;
    max-width: $arrangement-max-container-width;
  }
}

.section-header__navigation + .section-header__title-wrapper {
  margin-left: 3rem;
}

.section-header__title {
  align-items: center;
  color: $text-color;
  display: flex;

  .section-header__title-label {
    align-items: center;
    border: 1px solid transparent;
    display: flex;
    font-family: $font-family-header;
    font-size: 1.5rem;
    height: 2.5rem;
    padding: 0;

    &.section-header__title-label--editable {
      padding: 0 .625rem 0;
      transition: border .3s ease-in-out;

      &:focus {
        min-width: 260px;
      }

      .icon {
        font-size: .875rem;
        margin-left: .75rem;
        opacity: 0;
        transition: opacity .3s ease-in-out;

        &::before {
          color: $heading-light-color
        }
      }

      .section-header__title-wrapper:hover &,
      &:focus,
      &:hover {
        border: 1px solid $border-color;
        outline: none;

        .icon {
          opacity: 1;
        }
      }
    }
  }

  .section-header__title-icon {
    align-items: center;
    color: $text-color;
    display: flex;
    margin-left: .5rem;
    position: relative;
    top: -1px;
    transform: scale(-1, 1);
  }
}

.section-header__message-container {
  align-items: center;
  display: flex;

  &:not(:last-child) {
    margin-right: 1rem;
  }

  .icon-help {
    color: $medium-grey;
    margin-right: .5rem;
  }
}
