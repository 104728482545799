$item-margin: 1.25rem;

.cards-container {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  gap: 1rem;
}

a-card:not(.learning-item--no-interaction) {
  transition: border .3s ease-in-out;

  &:hover {
    border-color: var(--tm-color-blue-400);
  }

  cursor: pointer;
}

a-card {
  &::part(container) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    block-size: 100%;
    inline-size: 100%;
  }
  &::part(main) {
    margin-bottom: auto;
  }
}

.cards-container.arrangements-index--user {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  @media only screen and (max-width: 700px) {
    grid-template-columns: repeat(1, 1fr);
  }

  @media only screen and (min-width: 701px) and (max-width: ($fullhd - 1px)) {
    grid-template-columns: repeat(2, 1fr);
  }

  a-card {
    .card-description {
      white-space: pre-line;
      margin-top: 0;
      margin-bottom: 1rem;
      font-size: .875rem;
    }
    .card-footer {
      display: flex;
      justify-content: space-between;
    }
    .card-info {
      color: var(--tm-color-grey-600);
      font-size: 0.875rem;
      padding: 0.375rem;
    }
  }
}

span.highlighted-text {
  background-color: var(--tm-color-orange-300);
}

.card-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 0.5rem;
  position: relative;
  margin-bottom: -0.5rem;
  &__title {
    font-family: $family-serif;
    font-size: 1rem;
    margin-top: 0;
    margin-right: auto;
  }
  a-icon {
    min-inline-size: 1.5rem;
    margin-left: -0.2rem;
  }
  a-action-menu,
  a-icon-button.card-header__icon-button {
    margin: -0.7rem -0.7rem -0.7rem 0;
  }
}

.cards-container.arrangements-index--user {
  .card-header__title {
    font-size: 1.3rem;
    margin-top: -0.2rem;
  }
}

a-card.learning-item {
  .card-header {
    a-icon {
      margin-top: -0.15rem;
    }
  }
}

a-card.learning-item--no-replacement {
  a-icon {
    color: $warning-color;
  }
}

.card-groups {
  display: flex;
  flex-direction: column;
  &__label {
    cursor: pointer;
    font-weight: bold;
    display: flex;
    align-items: center;
    margin-bottom: 0.25rem;
  }
}

.card {
  background-color: $white;
  cursor: pointer;
  display: flex;
  margin-bottom: $item-margin;

  &--landscape {
    min-height: 220px;

    @media only screen and (max-width: 700px) {
      flex: 1 1 100%;
    }

    @media only screen and (min-width: 701px) and (max-width: ($fullhd - 1px)) {
      flex: 0 1 calc(50% - #{divide($item-margin, 2)});

      &:not(:nth-child(2n)) {
        margin-right: $item-margin;
      }
    }

    @media only screen and (min-width: $fullhd) {
      flex: 0 1 calc(32.99% - #{divide($item-margin, 2)});

      &:not(:nth-child(3n)) {
        margin-right: $item-margin;
      }
    }
  }

  &--portrait {
    min-height: 16.5rem;
    max-width: 12.5rem;
  }
}
