
.breadcrumb {
  display: flex;
  position: relative;
  flex-wrap: wrap;
  list-style: none;
  flex-direction: column;
  @include tablet() {
    flex-direction: row;
  }
}

.breadcrumb__item {
  line-height: 1.5rem;

  @include tablet() {
    &:after {
      font-family: $icon;
      content: $icon-chevron-right;
      display: inline-block;
      font-size: 1.5rem;
      color: $breadcrumb-item-color;
      vertical-align: middle;
      margin-right: 0.15em;
    }
    &:last-child:after { display: none; }
  }
}

.breadcrumb--simplified {
  align-items: center;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  @include tablet() { justify-content: center; }
}

.breadcrumb__link-container {
  display: flex;
  align-items: center;

  @include tablet() {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
}

.breadcrumb--simplified__link {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: $body-color;
  font-weight: $weight-bold;

  .icon-tm-chevron-left {
    line-height: 0;
    font-size: 1.5em;
    margin-right: 0.25em;
    color: $link-color;
  }
}
