$light-grey: #E7E7E7;

a-content-modal {
  &::part(dialog) {
    padding: 0 1rem;
    overflow: visible;
  }
}

.a-content-modal-title {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1rem;
  display: flex;
  gap: 0.5rem;
  justify-content: flex-start;
  align-items: center;
}

.a-modal-content {
  max-inline-size: 75vw;
  inline-size: 40rem;
  min-inline-size: 20rem;
  max-block-size: 75vh;
}

.a-modal-content__arrange-content {
  display: grid;
  grid-template-columns: 1fr 1fr;
  &--available,
  &--arrangement {
    box-sizing: border-box;
    padding: 0 0 0 0.5rem;
    max-block-size: 70vh;
    overflow: auto;
  }
  &--available {
    border-right: 1px dotted $light-grey;
  }
  &--title {
    font-weight: bold;
    padding: 0 0 0.5rem 0.5rem;
  }
}

.new-arrangement-container {
  a-text-input {
    box-sizing: border-box;
    margin-bottom: 1rem;
  }
  a-text-area {
    box-sizing: border-box;
    &::part(textarea) {
      box-sizing: border-box;
      width: 100%;
    }
  }
}
