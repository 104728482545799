.section-header__navigation {
  .link-button.router-link-active {
    align-items: center;
    display: inline-flex;

    .icon-tm-chevron-left {
      color: darken($primary-button-color, 7.5%);
      display: inline-flex;
      font-size: 1.5rem;
    }
  }
}
