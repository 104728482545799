.modal-context-menu {
  display: flex;
  flex-direction: column;
}

.modal-context-menu__item {
  color: $anthracite;
  display: flex;
  font-size: 1.25rem;
  font-weight: bold;
  padding: 1.125rem;
  transition: all .3s ease-in-out;

  &:hover {
    color: $heading-light-color;
  }

  &:not(:last-child) {
    border-bottom: 1px solid $blue-light-pale;
  }
}
