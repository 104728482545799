$star-size: 1.875rem;

.self-rating__instruction {
  font-size: 1.1rem;
}

.self-rating {
  .self-rating__stars {
    direction: rtl;
    border: 0 none;
    padding: 0;
    margin: 0 0 0 -4px;
    height: 30px;
    font-size: 0;
    position: relative;
    display: inline-block;
    overflow: visible;
    width: 185px;

    .self-rating__instruction {
      direction: ltr;
      opacity: 1;
      width: 300px;
      overflow: hidden;
      white-space: nowrap;
      position: absolute;
      left: 190px;
      top: 2px;
      text-align: left;
      line-height: 30px;
    }

    .self-rating-1,
    .self-rating-2,
    .self-rating-3,
    .self-rating-4,
    .self-rating-5 {
      opacity: 0;
      position: absolute;
      left: 190px;
      top: 2px;
      text-align: left;
      font-size: 18px;
      line-height: 30px;
    }

    label {
      display: inline-block;
      height: 30px;
      line-height: 36px;
      overflow: visible;
      padding: 0 8px 0 0;
      white-space: nowrap;
      width: 36px;
      vertical-align: baseline;
      & > span {
        width: 300px;
        direction: ltr;
        color: $base-text-color;
      }
    }

    #star-1 + label:hover,
    #star-2 + label:hover,
    #star-3 + label:hover,
    #star-4 + label:hover,
    #star-5 + label:hover {
      & > span {
        opacity: 1 !important;
        background-color: $question-self-score-background;
        z-index: 2;
      }
      & ~ .self-rating__instruction {
        opacity: 0;
      }
    }

    #star-1 + label:hover,
    #star-1 + label:hover ~ label {
      color: $tm-perfect;
    }

    #star-2 + label:hover,
    #star-2 + label:hover ~ label {
      color: $tm-good;
    }

    #star-3 + label:hover,
    #star-3 + label:hover ~ label {
      color: $tm-good;
    }

    #star-4 + label:hover,
    #star-4 + label:hover ~ label {
      color: $tm-unsatisfactory;
    }

    #star-5 + label:hover,
    #star-5 + label:hover ~ label {
      color: $tm-poor;
    }

    &:not(:checked) > input {
      position: absolute;
      top: 0;
      left: 0;
      clip: rect(0,0,0,0);
      display: none;
    }

    &:not(:checked) > label {
      overflow: hidden;
      white-space: nowrap;
      cursor: pointer;
      font-size: 0;
      color: $tm-neutral;
    }

    &:not(:checked) > label:before {
      content: $pika-star;
      font-family: $pika;
      font-size: $star-size;
    }

    & > input:checked {
      &#star-1 {
        & ~ label,
        &:hover ~ label {
          color: $tm-perfect !important;
        }
      }
      &#star-2 {
        & ~ label,
        &:hover ~ label {
          color: $tm-good !important;
        }
      }
      &#star-3 {
        & ~ label,
        &:hover ~ label {
          color: $tm-good !important;
        }
      }
      &#star-4 {
        & ~ label,
        &:hover ~ label {
          color: $tm-unsatisfactory !important;
        }
      }
      &#star-5 {
        & ~ label,
        &:hover ~ label {
          color: $tm-poor !important;
        }
      }
      &:before {
        content: $pika-star;
        font-size: $star-size;
      }
    }

    &:not(:checked) > label:hover,
    &:not(:checked) > label:hover ~ label {
      &:before {
        content: $pika-star;
        font-size: $star-size;
      }
    }
    & > input:checked + label:hover,
    & > input:checked + label:hover ~ label,
    & > input:checked ~ label:hover,
    & > input:checked ~ label:hover ~ label,
    & > label:hover ~ input:checked ~ label {
      color: $tm-unsatisfactory;
      &:before {
        content: $pika-star;
        font-size: $star-size;
      }
    }
    input:checked {
      & + label > span {
        background: $question-self-score-background;
        display: block;
        opacity: 1;
      }
      & ~ .self-rating__instruction {
        display: none;
        opacity: 0;
      }
    }
  }
}

.self-rating--completed {
  .self-rating-1,
  .self-rating-2,
  .self-rating-3,
  .self-rating-4,
  .self-rating-5 {
    display: none;
  }

  .stars {
    font-size: $star-size;
    font-family: $pika;
    display: inline-block;
    min-width: 20px;
    margin-right: 0.3em;
    vertical-align: top;
    letter-spacing: 8px;
    &:after {
      color: $tm-neutral;
    }
  }
}

.self-rating--completed.self-rating--result-20 {
  .stars {
    &:before {
      content: $pika-star;
      color: $tm-poor;
    }
    &:after {
      content: $pika-star + $pika-star + $pika-star + $pika-star;
    }
  }
}

.self-rating--completed.self-rating--result-40 {
  .stars {
    &:before {
      content: $pika-star + $pika-star;
      color: $tm-unsatisfactory;
    }
    &:after {
      content: $pika-star + $pika-star + $pika-star;
    }
  }
}

.self-rating--completed.self-rating--result-60 {
  .stars {
    &:before {
      content: $pika-star + $pika-star + $pika-star;
      color: $tm-good;
    }
    &:after {
      content: $pika-star + $pika-star;
    }
  }
}

.self-rating--completed.self-rating--result-80 {
  .stars {
    &:before {
      content: $pika-star + $pika-star + $pika-star + $pika-star;
      color: $tm-good;
    }
    &:after {
      content: $pika-star;
    }
  }
}

.self-rating--completed.self-rating--result-100 {
  .stars {
    &:before {
      content: $pika-star + $pika-star + $pika-star + $pika-star + $pika-star;
      color: $tm-perfect;
    }
  }
}
