@import './detail/arrangement-items';
@import './detail/connect-modal-content';
@import './detail/filter-accordion';
@import './detail/selected-filters';

.arrangement-detail {
  align-self: stretch;
  display: flex;
  flex: 1;
}

.arrangement__columns-container {
  flex: 1;
}

.arrangement__column {
  flex: 1 1 100%;
  height: 100%;

  &--search-filter {
    background: $blue-light-pale;
    border-right: 1px solid $form-element-border-color;
    display: flex;
    flex: 1 1 70%;
    body.admin-mode & {
      background: $tm-blue;
    }
  }

  &--arrange {
    background-color: $white;
    flex: 1 1 30%;
    display: flex;
    flex-direction: column;
    margin-right: calc(50% - 50vw);
  }

  .arrangement-column__header {
    align-items: center;
    color: $body-color;
    display: flex;
    font-family: $family-serif;
    font-size: 1.25rem;
    font-weight: 400;

    .admin-mode & {
      color: $white;
    }
  }
}

/**
  SEARCH/FILTERS COLUMN
 */
.arrangement-search-controls {
  background: $white;
  border-right: 1px solid $form-element-border-color;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  max-width: $arrangement-search-controls-max-width;
  width: 100%;
  max-height: calc(100vh - #{$arrangement-section-header-height} - #{$arrangement-section-header-border-bottom});
}

/**
  RESULTS COLUMN
 */
.arrangement-search-results {
  align-items: flex-start;
  display: flex;
  justify-content: flex-start;
  //max-width: calc(calc(#{$arrangement-max-container-width} - #{$arrangement-search-controls-max-width}) - #{$arrangement-learning-items-container-max-width});
  overflow: hidden;
  padding-left: 1.5rem;
  padding-top: 1.5rem;
  transition: all .5s ease-in-out;
  //width: calc(calc(100vw - #{$arrangement-search-controls-max-width}) - #{$arrangement-learning-items-container-max-width});
  width: 100%;

  .arrangement-search-results__icon {
    display: inline-flex;
    width: .75rem;

    > svg {
      flex: 1
    }
  }

  .arrangement-search-results__label {
    position: absolute;
    bottom: -1rem;
    color: $heading-light-color;
    font-size: 0.75rem;
    font-weight: bold;
    .admin-mode & {
      color: white;
    }
  }

  .arrangement-search-results__search-container {
    margin-right: 2rem;
  }

  &.collapsed {
    flex-flow: row wrap;
    justify-content: center;
    width: 3.125rem;
    padding-left: 0;

    .arrangement-search-results__header {
      display: flex;
      flex: 1 1 100%;
    }

    .arrangement-search-results__header-inner {
      position: relative;
      display: flex;
      flex: 1 1 100%;
    }

    .arrangement-column__header {
      align-items: center;
      display: flex;
      flex: 1;
      transform: rotate(-180deg);
      writing-mode: vertical-lr;
      width: 100%;
    }

    .arrangement-search-results__icon {
      height: .75rem;
      margin-bottom: .5rem;
      margin-top: 2.5rem;
      margin-left: .375rem;
      order: 2;
      transform: rotate(180deg);
    }
  }

  &.expanded {
    flex-flow: column wrap;

    .arrangement-search-results__header {
      position: relative;
      height: 2.25rem;
      width: 100%;

      .arrangement-search-results__header-inner,
      .arrangement-column__header,
      .arrangement-search-results__icon {
        height: inherit; // Safari-specific fix for this kinda 'complex' layout (with collapse/expand, etc)
      }

      .sorting-select {
        margin-top: -0.3rem;
      }

      .dropdown {
        white-space: nowrap;
      }
    }
  }
}

.search-results-outer-wrapper {
  flex: 1 0 auto;
  height: 0;
  overflow: hidden;
  padding-bottom: 5rem; // add some space for the 'observedElement' to properly become visible at the bottom
  width: 100%;
  position: relative;
}

.search-results-inner-wrapper {
  height: 100%;
  overflow-y: scroll;
  overflow-x: auto;
  width: 100%;

  &--extra-margin-bottom::after {
    content: '';
    display: flex;
    flex: 1 1 auto;
    min-height: 3rem;
    width: 100%;
  }
}

.search-results-container {
  display: flex;
  flex-direction: column;
  max-height: min-content;
  min-height: min-content;
  width: 100%;

  &.search-results-container--bottom-gap::after {
    content: '';
    display: flex;
    flex: 1 1 auto;
    min-height: 20vh;
    width: 100%;
  }

  &__inner {
    align-content: baseline;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    gap: 1rem;
    margin-right: 1rem;
    @include widescreen() {
      grid-template-columns: minmax(0, 1fr) minmax(0, 1fr);
    }

    &:empty {
      min-height: 50vh;
    }
  }
}

.search-results-loadmore-label {
  color: $muted-text-color;
  cursor: pointer;
  text-align: center;
  user-select: none;
  margin-top: 1rem;

  @media screen and (max-width: 1024px) {
    font-size: 1rem;
  }

  .admin-mode & {
    color: $muted-text-color-admin;
  }
}

.search-results-loadmore-trigger {
  display: block;
  height: 1rem;
  margin-top: 10rem;
}

.gutter.gutter-horizontal {
  cursor: ew-resize;
}
