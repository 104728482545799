$arrangement-max-container-width: 120rem;
$arrangement-learning-items-container-max-width: 500px;
$arrangement-search-controls-max-width: 20rem;
$arrangement-section-header-height: 57px;
$arrangement-section-header-border-bottom: 1px;
$arrangement-learning-item-base-width: 250px;

$anthracite: #343434;
$medium-grey: #CACACA;
$light-grey: #E7E7E7;

$blue-light-pale: var(--tm-color-blue-100);

$red-bright: #E2004F;
$red-regular: #D1272E;
$red-dark: #A62126;

$green-pale: #96BD0D;
$green-bright: #30B93C;
$green-dark: #4B8C3C;

$border-radius-default: 4px;

$text-color: $anthracite;
$border-color: $light-grey;
$input-border-color: $medium-grey;

$background-panel: $blue-light-pale;

$button-font-size: .75rem;
$button-border-radius: $border-radius-default;
$button-background-color-primary: $red-regular;
$button-background-color-primary-hover: $red-dark;

$form-element-border-color: $medium-grey;
$form-element-text-color: $heading-light-color;
$form-element-font-size: 1rem;
$form-element-font-family: $family-sans-serif;
$font-family-header: $family-serif;

@import 'arrangements/buttons';
@import 'arrangements/pills';
@import 'arrangements/dropdowns';
@import 'arrangements/inputs';
@import 'arrangements/cards';
@import 'arrangements/navigation';
@import 'arrangements/section-header';
@import 'arrangements/arrangements-list';
@import 'arrangements/methods-list';
@import 'arrangements/arrangement-detail';
@import 'arrangements/templates-list';
@import 'arrangements/generic/modal';
@import 'arrangements/generic/modal-context-menu';
@import 'arrangements/generic/modal-new-arrangement';
@import 'arrangements/generic/modal-fix-arrangement';

html {
  height: 100%;
}

body,
html {
  margin: 0;
  padding: 0;
}

.arrangements-page {
  background: $blue-light-pale;
  display: flex;
  flex-direction: column;
  height: auto;
  min-height: 100%;
}

.arrangement-page {
  background: $white;
}

.disable-body-scroll {
  overflow: hidden;
}

.admin-predefined-arrangements {
  background-color: $tm-background-blue;
  min-height: 100vh;
  .arrangements-container {
    height: calc(100vh - 126px)
  }
}
.arrangements-module-wrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 100vw;
  overflow-x: hidden;

  &--admin {
    padding-top: 50px;
    min-height: 100vh;
  }
}

.admin-mode:not(.arrangement-page) {
  background-color: $tm-blue;
}

.landing-page {
  .title {
    justify-content: center;
    font-weight: 400;
    margin: 0;
    padding: 0;
  }
}

a-action-menu {
  --z-index: 10000;
  a-list-item {
    a-button::part(button) {
      padding: 0.25rem 1rem;
    }
    &:first-child {
      a-button::part(button) {
        margin-top: 0.25rem;
      }
    }
    &:last-child {
      a-button::part(button) {
        margin-bottom: 0.25rem;
      }
    }
  }
}
