.fix-arrangement-header {
  display: flex;
}
.fix-arrangement-header__label {
  align-items: center;
  color: $anthracite;
  display: inline-flex;
  font-size: 1.25rem;
  font-weight: bold;
}
.fix-arrangement-description {
  margin-top: 0.5rem;
  color: $tm-neutral-black;
}
