.labeled-title {
  display: flex;
  align-items: flex-start;
  line-height: 1.1em;
  font-family: $family-serif;
  font-weight: $weight-normal;
  color: $body-color;
  text-rendering: optimizeLegibility;
  @include empty-placeholder();
}

.labeled-title__text {
  word-break: normal;
}

.labeled-title__label {
  font-size: .675em;
  font-family: $family-sans-serif;
  font-weight: $weight-bold !important;
  color: $white !important;
  text-transform: uppercase;
  border-radius: 2px;
  line-height: 1em;
  background-color: $tm-neutral-black;
  text-align: center;
  white-space: nowrap;
  padding: .2em .75em;
  margin-right: .5em;
  @include empty-placeholder();
}

.labeled-title__text--sans-serif { font-family: $family-sans-serif; }
.labeled-title__text--bold { font-weight: $weight-bold; }

.labeled-title--small {
  .labeled-title__text {
    margin-top: 0.175em;
    font-family: $family-sans-serif;
  }
  .labeled-title__label {
    font-size: 1em;
  }
}

.labeled-title--with-button {
  .labeled-title__text { align-self: center; }
  .labeled-title__button-container {
    flex-shrink: 0;
    align-self: center;
    margin-left: auto;
    display: flex;
    align-items: center;
  }
}

.labeled-title--hoverable {
  .labeled-title__text-content { border-bottom: 1px dashed currentColor; }

  &:hover {
    .labeled-title__text-content { color: $link-color; }
  }
}
