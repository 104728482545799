.pill {
  background-color: $green-bright;
  border-radius: .3125rem;
  color: $white;
  font-size: .75rem;
  font-weight: bold;
  padding: .1875rem .5rem;

  &-control {
    min-width: 0.5rem;
    cursor: pointer;
    display: inline-flex;
    height: .375rem;
    margin-left: .5rem;
    width: .375rem;

    svg {
      fill: rgba(0, 0, 0, .5);
      height: 100%;
    }
  }

  &--large {
    padding: .375rem .5rem;
  }

  &.selected-filters__item {
    padding-right: 1rem;
    position: relative;
    width: 100%;
    .pill-control {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
    }
  }
}
