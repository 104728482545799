.selected-filters-clear-trigger {
  color: $heading-light-color;
  font-size: .75rem;
  font-weight: bold;
}

.selected-filters__inner {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.selected-filters__item {
  flex: 0;
}
