.learn-lines-navigation__list--level-3 {
  margin: 0;

  .learn-lines-navigation__item {
    align-items: center;
    background-color: $white;
    border-bottom: 1px solid $cockpit-content-border-bottom-color;
    display: flex;
  }

  .learn-lines-navigation__item-link {
    align-items: center;
    color: $cockpit-body-color;
    display: flex;
    min-height: 3.25rem;
    padding: .5rem 0;
    width: calc(100% - 40px);
  }
}
