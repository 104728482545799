%add-grap-cursor {
  cursor: move; /* fallback if grab cursor is unsupported */
  cursor: grab;
  cursor: -moz-grab;
  cursor: -webkit-grab;

  &:active {
    cursor: grabbing;
    cursor: -moz-grabbing;
    cursor: -webkit-grabbing;
  }
}

.learning-items-container-outer-wrapper {
  overflow: auto;
  // these are needed so dragging works inside this container, if the mouse enters this area while dragging, it will scroll
  border-top: 2rem solid $white;
  border-bottom: 2rem solid $white;
  height: 0;
  flex: 1 0 auto;

  .learning-item .learning-item__content {
    cursor: pointer;
  }
}

.learning-items-container {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-row-gap: 1.5rem;

  &--arrangement {
    display: flex;
    flex-direction: column;
    min-block-size: 100%;
  }

  &--with-replacements {
    max-height: calc(90vh - 400px);
    overflow-y: auto;
    grid-template-columns: 1fr 3rem 1fr;
    .learning-items-container__icon {
      color: $tm-neutral-black;
      font-size: 1.5rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &--empty {
    height: auto;
    max-width: $arrangement-learning-items-container-max-width;

    &:empty::before {
      background-color: $light-grey;
      content: attr(data-empty-items-message);
      color: $heading-light-color;
      display: inline-flex;
      font-size: .875rem;
      padding: 1.5rem;
      user-select: none;
      block-size: max-content;
    }
  }
}

.learning-item {
  &--no-interaction {
    cursor: auto;
  }

  &--draggable {
    @extend %add-grap-cursor;
  }

  &--dragging-placeholder {
    border-color: var(--tm-color-blue-400);
    border-style: dotted;
  }

  * {
    user-select: none;
  }

  &__inner {
    background-color: $white;
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
  }

  &.sortable-drag .learning-item__inner {
    transform-origin: 20% 50%;
    transform: rotate(-4deg) !important;
  }

  &.sortable-ghost .learning-item__inner {
    background-color: $light-grey;

    * {
      display: none;
    }
  }

  .learning-items-container & {
    max-width: $arrangement-learning-items-container-max-width;

    &__inner {
      border: 1px solid $light-grey;
      border-radius: 3px;
    }

    &.learning-item--already-used {
      @extend %add-grap-cursor;
    }
  }

  .search-results-container & {
    &.learning-item--already-used .learning-item__inner {
      background-color: $medium-grey;
    }

    &.learning-item--still-available {
      @extend %add-grap-cursor;
    }
  }

  .drag-control {
    @extend %add-grap-cursor;

    align-items: center;
    display: flex;
    justify-content: center;
    width: 1.875rem;

    .inline-svg {
      fill: $form-element-border-color;
      width: .375rem;
    }
  }

  &--unavailable {
    .learning-item__inner {
      border: 1px solid $warning-color;
    }
  }

  &--empty {
    .learning-item__inner {
      align-items: center;
      justify-content: center;
      border: 0 none;
      font-size: 2rem;
      color: $warning-color;
    }
    .learning-item-icon--no-replacement {
      font-size: 1.5rem;
      color: $warning-color;
    }
  }

  //&__warning-icon {
  //  display: block;
  //  position: absolute;
  //  left: 0.2rem;
  //  top: 0.2rem;
  //  .inline-svg {
  //    fill: $warning-color;
  //    display: block;
  //    width: 1rem;
  //    height: 1rem;
  //  }
  //}
}

.learning-items-container .sortable-chosen .drag-control {
  display: flex !important;
}

.learning-item__content {
  display: flex;
  flex: 1;
  overflow: hidden;
  flex-direction: column;
  padding: 1.125rem;

  .learning-item--condensed & {
    padding: 1rem;
  }
}

.learning-item__header {
  font-family: $family-serif;
  font-size: 1rem;
  font-weight: bold;
  margin-right: 1.5rem;

  .learning-item--condensed & {
    font-size: 0.9rem;
    font-weight: normal;
  }
}

.learning-item__content-divider {
  background: $light-grey;
  height: 1px;
  min-height: 1px;
  width: 100%;
}

.learning-item__bread-crumb {
  color: $heading-light-color;
  font-size: .875rem;
  margin-top: 3px;
}

.learning-item__metadata-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  color: $heading-light-color;
  font-size: .875rem;
  .learning-item__metadata {
    flex-grow: 1;
    flex-shrink: 1;
    margin: 0 0.2rem;
    text-align: center;
    &:only-child {
      margin: 0;
      text-align: left;
    }
  }
  .learning-item__metadata-divider {
    background: $light-grey;
    width: 1px;
    min-width: 1px;
    margin: 0 0.2rem;
  }
}

.add-remove-control {
  align-items: center;
  display: flex;
  justify-content: center;
  position: absolute;
  right: 0.625rem;
  top: 0.625rem;

  width: 2.25rem;
  height: 2.25rem;
  .inline-svg {
    display: block;
    width: .5rem;
  }

  .learning-item--still-available & svg {
    transform: rotate(45deg);
  }
}

/* Learning-items detail view */
.learning-item-details-container {
  color: $text-color;
}

.learning-item-details__title {
  font-size: 1.25rem;
  margin-bottom: .625rem;
}
