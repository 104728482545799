.learn-lines-navigation__list--level-1 {

  .learn-lines-navigation__item {
    margin-bottom: 0;
    transition: margin .6s ease-in-out;

    .learn-lines-navigation__list-wrapper {
      margin: 0;
      max-height: 0;
      opacity: 0;
      overflow: hidden;
      transition: all .3s ease-in-out;
    }
  }

  .learn-lines-navigation__item-inner {
    align-items: center;
    background-color: $cockpit-content-navigation-bg-color;
    border-radius: $cockpit-border-radius;
    color: $cockpit-content-navigation-bg-color;
    display: flex;
    height: 4.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;
    overflow: hidden;
    padding: 1rem 1.5rem;

    &:hover {
      cursor: pointer;
    }
  }

  .learn-lines-navigation__item-title {
    color: $cockpit-body-color;
    font-family: $cockpit-header-font;
    font-size: 1.5rem;
    font-weight: normal;
    transition: all .3s ease-in-out;
  }

  .learn-lines-navigation__item-icon {
    align-items: center;
    color: $cockpit-medium-grey;
    display: flex;
    font-size: 2rem;
    transform: rotateZ(0deg);
    transition: transform .3s ease-in-out;

    &::before {
      display: block;
    }
  }

  .learn-lines-navigation__item--opened {
    margin-bottom: 1.5rem;

    .learn-lines-navigation__item-title {
      color: $cockpit-light-blue;
      font-weight: bold;
    }

    .learn-lines-navigation__item-icon {
      transform: rotateZ(180deg);

      path {
        fill: $cockpit-dark-grey;
      }
    }

    .learn-lines-navigation__list-wrapper {
      max-height: 100%;
      opacity: 1;
    }
  }
}
