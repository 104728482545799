.score-label {
  font-size: 1em;
  font-weight: $weight-bold;
  padding: $score-label-padding;
  border-radius: $score-label-border-radius;

  &--with-education-level-marker {
    position: relative;
    strong {
      padding-left: 1rem;
    }
  }

  &--inline {
    padding: 0;
  }

  .score-label__education-level-marker {
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    line-height: 2rem;
    font-size: 0.75rem;
    width: 0.9rem;
    height: 100%;
    font-weight: $weight-bold;
    background-color: $tm-neutral-light;
    .results-body__column--hoverable {
      &:hover {
        background-color: $table-highlight-color;
      }
    }
  }

  @include score-label($hover: false);

  &--none {
    color: $base-text-color;
  }

  &--no-questions {
    background-color: $tm-neutral-pale;
    &:before {
      content: '\00a0';
    }
  }
  &--advice {
    @include empty-placeholder();
  }
  &--progress {
    padding: 0.1rem 0.3rem;
    border-radius: 0.2rem;
    min-width: 3rem;
    text-align: center;
    flex-grow: 0;
  }

}
