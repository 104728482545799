$baseline: 1.5;
@function baseline($multiply: 1) {
  @return ($baseline * $multiply) + rem;
}

html {
  font-size: 16px;
  line-height: $baseline;
}

body {
  line-height: $baseline;
  font-size: 1rem;
}

p,
ul,
ol {
  margin-bottom: 1rem;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  line-height: 1.25;
  margin-bottom: 0.5rem;
  font-weight: 700;
  color: currentColor;

  [class^="icon-"]:before,
  [class*=" icon-"]:before {
    font-size: 0.8em;
  }
}

$heading-sizes: (
  h1: (
    mobile: 2rem,
    desktop: 3rem
  ),
  h2: (
    mobile: 1.75rem,
    desktop: 2.5rem
  ),
  h3: (
    mobile: 1.5rem,
    desktop: 1.75rem
  ),
  h4: (
    mobile: 1.25rem,
    desktop: 1.5rem
  ),
  h5: (
    mobile: 1.15em,
    desktop: 1.2rem
  ),
  h6: (
    mobile: 1.1rem,
    desktop: 1.1rem
  )
);

@each $heading, $sizes in $heading-sizes {
  #{$heading}, .#{$heading} {
    font-size: map-get($sizes, mobile);
    @include from($tablet) {
      font-size: map-get($sizes, desktop);
    }
    .is-breakpoint-mobile & {
      font-size: map-get($sizes, mobile);
    }
  }
}

strong {
  color: currentColor;
  font-weight: $weight-bold;
}

.text-muted {
  font-size: 0.875em;
  color: $muted-text-color;
}

.text-bold { font-weight: $weight-bold; }
.text-normal { font-weight: $weight-normal; }
.text-light { color: $heading-light-color; }
.text-white { color: $white; }
.text-good, .text-green { color: $tm-good-dark; }

.text-align-left { text-align: left; }
.text-align-right { text-align: right; }
.text-align-center { text-align: center; }
.text-align-justify { text-align: justify; }
.vertical-align-top { vertical-align: top; }
.vertical-align-middle { vertical-align: middle; }
.vertical-align-bottom { vertical-align: bottom; }

.whitespace-nowrap { white-space: nowrap; }

.text-tm-poor-dark { color: $tm-poor-dark; }
.text-tm-good-dark { color: $tm-good-dark; }
.text-tm-unsatisfactory-dark { color: $tm-unsatisfactory-dark; }
.text-tm-green { color: $tm-green; }
.text-tm-purple-dark { color: $tm-purple-dark; }

i, strong, em {
  &.with-trailing-space {
    &:after {
      content: ' ';
    }
  }
  &.with-leading-space {
    &:before {
      content: ' ';
    }
  }
}
