.learn-lines-navigation__list-wrapper {
  display: flex;
  width: 100%;

  > .flexbox {
    width: 100%;
  }

  .tabs {
    display: flex;
    flex-direction: column;
  }

  .tab-pages {
    margin-left: 1.5rem;
    margin-top: .6rem;
    position: relative;

    &,
    .tab-content {
      width: 100%;
    }
  }
}

.learn-lines-navigation__list--level-2 {
  max-width: 22.5rem;
  width: 30vw;

  .tabs__item {
    .tabs__link {
      align-items: center;
      background-color: $white;
      color: $cockpit-body-color;
      display: flex;
      font-weight: bold;
      overflow: hidden;
      padding: .5rem 1rem;
      text-overflow: ellipsis;
    }

    .tabs__link--active {
      background-color: $cockpit-almost-white-blue;
      border-radius: $cockpit-border-radius;
      color: $cockpit-blue;

      &::after {
        content: none;
      }
    }
  }
}
