/** FILTERS ACCORDION **/
.arrangement-filters-accordion {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.filter-accordion_header {
  align-items: center;
  color: $heading-light-color;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  font-family: $family-sans-serif;
  margin: 0;

  .admin-mode & {
    color: white;
  }
}

.filter-accordion_item {
  border-top: 1px solid white;

  .filter-accordion_icon {
    text-align: center;
    transition: transform .3s ease;
    transform: rotate(0);
    transform-origin: 50% 60%;
    width: .5rem;

    &::before {
      font-size: 1.75rem;
    }

    &.rotated {
      transform: rotate(180deg);
    }
  }

  .admin-mode & {
    .filter-accordion_icon {
      fill: white;
    }
  }
}

.filter-option-link {
  color: $heading-light-color;
  display: flex;
  font-size: .875rem;
  padding: .5rem 0;

  &.selected,
  &:hover {
    color: $green-bright;
  }

  &.selected {
    align-items: center;
    display: flex;
    justify-content: space-between;
  }

  &.disabled {
    color: $tm-neutral-dark;
  }
}

.filter-option-text {
  display: flex;
  font-size: .8rem;
  padding: .5rem 0;
}

.arrangement-filter-panel-container {
  margin-left: -.5rem;
  padding-right: 1rem;
  margin-bottom: 3.5rem;
  overflow: auto;
}
